<template>
  <div>
    <Spinner v-if="loading" class="pt-5" />

    <div v-else-if="error" class="alert alert-danger">
      {{ error }}
    </div>

    <div v-else>
      <TabTitle class="mb-4">
        <template>{{ benchmark.name }}</template>
        <template #sub-title>{{ benchmark.description }}</template>
      </TabTitle>

      <!-- Dropdown with past 5 years -->
      <div class="row">
        <div class="col-md-2">
          <FormGroup id="category" v-model="category" label="CRREM Category" :options="categoryOptions" type="select" alt />
        </div>
        <div class="col">
          <label for="category" class="">Assets</label>
          <div class="input-group">
            <FormItem
              id="filterType"
              v-model="filterType"
              :options="[
                { value: 'asset', label: 'Asset' },
                { value: 'portfolio', label: 'Portfolio' }
              ]"
              type="select"
              alt
              style="max-width: 120px"
            />
            <div class="input-group-append">
              <FormItem id="filter" v-model="filter" :options="typeOptions" type="select" @input="loadData" />
            </div>
          </div>
        </div>
      </div>

      <apexchart class="mb-4" type="line" height="350" :options="pathwayChartOptions" :series="pathwaySeries" />

      <p class="font-w600 text-center">
        <i class="fa fa-exclamation-triangle text-warning"></i> The below table may be inaccurate if floor areas are not set for all your assets.
      </p>
      <table class="table">
        <thead>
          <tr>
            <th>Benchmark Categories</th>
            <th>Proportion</th>
            <th>Electricity (kWh/m<sup>2</sup>)</th>
            <th>Gas (kWh/m<sup>2</sup>)</th>
            <th>Total (kWh/m<sup>2</sup>)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cat in categoriesBreakdown" :key="cat.name">
            <td>{{ cat.name }}</td>
            <td>{{ cat.proportion.toFixed(2) }}%</td>
            <td>{{ cat.elecIntensity.toFixed(2) }}</td>
            <td>{{ cat.gasIntensity.toFixed(2) }}</td>
            <td>{{ cat.totalIntensity.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';
import TabTitle from '@/components/base/TabTitle';
import Spinner from '@/components/SpinnerLogo';
import { colours } from '@/lib/chartHelpers';

export default {
  components: {
    FormGroup,
    FormItem,
    TabTitle,
    Spinner
  },
  data() {
    return {
      loading: true,
      year: 2018,
      category: 'Office',
      filterType: 'asset',
      filter: null,
      error: ''
    };
  },
  computed: {
    ...mapGetters({
      benchmarkLoading: 'benchmark/loadingAction',
      benchmarks: 'benchmark/benchmarks',
      assets: 'asset/assets',
      assetGroups: 'assetGroup/assetGroups',
      analytics: 'analytics/analytics'
    }),
    benchmark() {
      return this.benchmarks.find(benchmark => benchmark.name === 'CRREM');
    },
    categoriesBreakdown() {
      const categories = [...this.benchmark.fields.find(c => c.code === 'category').data, 'N/A'];

      const populatedCategories = categories.map(cat => {
        const assetsInCategory = this.assets.filter(a => {
          const categorySet = (a.benchmarks || []).some(b => b.benchmarkId === this.benchmark._id && b.fieldCode === 'category');
          const category = (a.benchmarks || []).find(b => b.benchmarkId === this.benchmark._id && b.fieldCode === 'category' && b.fieldValue === cat);

          return categorySet ? category : cat === 'N/A';
        });

        const elecConsumption = this.analytics['elec-consumption']?.results?.benchmarkBreakdown?.[cat]?.consumption || 0;
        const elecFloorArea = this.analytics['elec-consumption']?.results?.totalFloorArea || 1;

        const gasConsumption = this.analytics['gas-consumption']?.results?.benchmarkBreakdown?.[cat]?.consumption || 0;
        const gasFloorArea = this.analytics['gas-consumption']?.results?.totalFloorArea || 1;

        // const totalFloorArea =
        //   assetsInCategory.reduce((acc, asset) => (acc + asset.floorAreaUnit === 'metric' ? asset.floorArea : asset.floorArea * 0.092903), 0) || 1;

        const elecIntensity = elecConsumption / elecFloorArea;
        const gasIntensity = gasConsumption / gasFloorArea;

        return {
          name: cat === 'N/A' ? 'Unspecified (Office)' : cat,
          assets: assetsInCategory.length,
          proportion: (assetsInCategory.length / this.assets.length) * 100,
          elecConsumption: elecConsumption,
          gasConsumption: gasConsumption,
          totalConsumption: elecConsumption + gasConsumption,
          elecIntensity: elecIntensity,
          gasIntensity: gasIntensity,
          totalIntensity: elecIntensity + gasIntensity
        };
      });

      populatedCategories.sort((a, b) => b.proportion - a.proportion);

      return populatedCategories;
    },

    crremCategoriesBreakdownByYear() {
      return this.benchmark.data.map(data => {
        const updatedValues = {};

        data.values
          .filter(v => v.scenario === '1.5')
          .forEach(value => {
            const yearElecConsumption = this.analytics['elec-consumption']?.results?.annuallyConsumption?.find(c => c.period === String(data.year));
            const elecFloorArea = this.analytics['elec-consumption']?.results?.totalFloorArea || 1;

            const yearGasConsumption = this.analytics['gas-consumption']?.results?.annuallyConsumption?.find(c => c.period === String(data.year));
            const gasFloorArea = this.analytics['gas-consumption']?.results?.totalFloorArea || 1;

            const totalFloorArea = elecFloorArea + gasFloorArea;

            let elecConsumption = yearElecConsumption?.benchmarkBreakdown?.[value.category]?.consumption || 0;
            let gasConsumption = yearGasConsumption?.benchmarkBreakdown?.[value.category]?.consumption || 0;

            if (value.category === 'Office') {
              elecConsumption += yearElecConsumption?.benchmarkBreakdown?.['N/A']?.consumption || 0;
              gasConsumption += yearGasConsumption?.benchmarkBreakdown?.['N/A']?.consumption || 0;
            }

            updatedValues[value.category] = {
              ...value,
              totalFloorArea: totalFloorArea,
              elecConsumption: elecConsumption,
              gasConsumption: gasConsumption,
              totalConsumption: elecConsumption + gasConsumption,
              elecIntensity: elecConsumption / elecFloorArea,
              gasIntensity: gasConsumption / gasFloorArea,
              totalIntensity: (elecConsumption + gasConsumption) / totalFloorArea
            };
          });

        return {
          ...data,
          values: updatedValues
        };
      });
    },
    pathwaySeries() {
      return [
        {
          name: 'Asset Carbon Intensity',
          type: 'column',
          data: this.crremCategoriesBreakdownByYear.map(data => data.values[this.category].totalIntensity)
        },
        {
          name: '1.5 Scenario',
          type: 'line',
          data: this.benchmark.data.map(data => data.values.find(v => v.category === this.category).energyIntensity)
        }
      ];
    },
    pathwayChartOptions() {
      return {
        chart: {
          type: 'line',
          height: 350,
          stacked: false
        },
        markers: {
          size: 1
        },
        stroke: {
          width: [0, 4]
        },
        yaxis: {
          decimalsInFloat: 0
        },
        xaxis: {
          categories: this.benchmark.data.map(data => data.year)
        },
        title: {
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 600
          }
        },
        colors: colours
      };
    },
    yearOptions() {
      const years = moment().diff('2018', 'years');

      return Array.from({ length: years }, (v, i) => moment('2018').year() + i).map(year => ({
        label: year,
        value: year
      }));
    },
    categoryOptions() {
      return this.benchmark.fields
        .find(c => c.code === 'category')
        .data.map(cat => ({
          label: cat,
          value: cat
        }));
    },
    typeOptions() {
      let options = [];

      if (this.filterType === 'asset') {
        options = this.assets.map(asset => ({
          label: asset.siteName,
          value: asset._id
        }));
      } else {
        options = this.assetGroups.map(assetGroup => ({
          label: assetGroup.name,
          value: assetGroup._id
        }));
      }

      // sort in alphabetical order
      options.sort((a, b) => a.label.localeCompare(b.label));

      return options;
    }
  },
  async mounted() {
    await Promise.all([
      this.getBenchmarks(),
      this.getAssets({ data: { params: { $select: 'siteName,benchmarks,floorArea,floorAreaUnit' } } }),
      this.getAssetGroups({ data: { params: { type: 'company' } } })
    ]);

    this.filter = this.typeOptions[0].value;

    if (this.assetGroups.length === 0) {
      this.error = 'No portfolio asset groups found. Please create a company-wide portfolio to view the pathway.';
      return;
    }

    await this.loadData();
  },
  methods: {
    ...mapActions({
      getBenchmarks: 'benchmark/list',
      getAssets: 'asset/list',
      getAnalytics: 'analytics/getAnalytics',
      getAssetGroups: 'assetGroup/list'
    }),
    async loadData() {
      this.loading = true;

      const params = {
        id: 'asset-emission',
        startDate: moment('01-01-2018').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        sourceData: 'invoice',
        dateBreakdown: 'annually',
        benchmarkBreakdown: this.benchmark._id,
        companyId: this.$auth.companyId
      };

      if (this.filterType === 'asset') {
        params.assetId = this.filter;
      } else {
        params.assetGroupId = this.filter;
      }

      await Promise.all([
        this.getAnalytics({
          id: 'elec-consumption',
          params: {
            ...params,
            id: 'asset-consumption'
          }
        }),
        this.getAnalytics({
          id: 'gas-consumption',
          params: {
            ...params,
            id: 'asset-consumption',
            accountType: 'gas'
          }
        })
      ]);

      this.loading = false;
    }
  }
};
</script>
